@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.card:hover .btn-hidden-cust {
  display: block !important;
  visibility: visible !important;
}

.card .invisible {
  visibility: hidden;
  position: absolute;
}

.card {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}
.card-hover:hover {
  transform: translateY(-5px);
}
.card-title {
  font-weight: bold;
}
.card-text {
  color: #6c757d;
}
.btn-donate {
  background-color: #28a745;
  border-color: #28a745;
  font-weight: bold;
}
.btn-donate:hover {
  background-color: #218838;
  border-color: #1e7e34;
}


