/* Color Variables */
:root {

  --button-color: #ffbf80;
  --button-text: #ffffff;

  --classic-base: #b594d8;
  --classic-text: #f8f5fb;

  --contrast-base: #4985f5;
  --contrast-text: #000;
}



/* Global Styles */
html {
  padding: 0;
  margin: 0;
}

.btn-contrast{
  background-color: var(--contrast-base) !important;
  color: var(--contrast-text) !important;
  
}

/* Navbar */
.navbar {
  background-color: var(--classic-base);
}
.nav-link, a.navbar-brand {
  color: var(--classic-text) !important;
}

.nav-link:hover, a.navbar-brand:hover{
  color: var(--classic-text) !important;
  
  padding-top: -10px;
}

/* Buttons */
.btn-primary {
  background-color: var(--button-color);
  border-color: var(--button-color);
  color: var(--button-text);
}
.btn-primary:hover {
  background-color: var(--button-color);
  border-color: var(--button-color);
}
.btn-primary:active,
.btn-primary.active {
  background-color: var(--button-color) !important;
  border-color: var(--button-color) !important;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.25rem var(--button-color);
}

/* Footer */
.footer {
  background-color: var(--classic-base);
}
.footer-wave-svg {
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
  background-color: transparent;
}
.footer-wave-path {
  fill: #fff;
}
.footer ul li a {
  color: var(--classic-text);
  text-decoration: none;
}

.footer-logo {
  color: var(--classic-text);
}

.footer-logo:hover{
  color: var(--contrast-text) !important;
}

.footer ul li a:hover {
  color: var(--contrast-text) !important;
  
}
.footer-list {
  list-style-type: none;
  padding-left: 0;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 18px;
  margin: 0;
}
.footer-menu {
  margin-top: 30px;
}
.footer-menu-name,
.footer-call-to-action-description,
.footer-call-to-action-title {
  color: var(--classic-text);
}


.footer-call-to-action-button {
  background-color: var(--contrast-base);
  border-radius: 21px;
  color: var(--contrast-text);
  display: inline-block;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 18px;
  padding: 12px 30px;
  margin: 0 10px 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid var(--contrast-base); 
  transition: background-color 0.2s;
  cursor: pointer;
  position: relative;
}
.footer-call-to-action-button:hover {
  background-color: var(--classic-base) !important;
  border: 1px solid var(--contrast-base);
  color: var(--secondary);
}
.footer-call-to-action-link-wrapper a {
  color: var(--contrast-text);
  text-decoration: none;
  background-color: var(--contrast-base);
  border-radius: 20px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding: auto;
}

.footer-call-to-action-link-wrapper a:hover{
  background-color: var(--classic-base) !important;
  border: 1px solid var(--contrast-base);
  color: var(--classic-text);
  font-weight: 700;
  font-size: larger;
}

.footer-copyright-text, .footer-copyright-link{
  padding: 1em;
  text-align: center;
  margin-bottom: 0 !important;
  background-color: var(--contrast-base);
  color: var(--contrast-text) !important;
}



/* Other Styles */
.random-card-container .card,
.success-stories-menu .card,
.support-dome-container .card {
  margin-bottom: 20px;
}
.location-page-container .map-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.location-page-container .map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
